import './App.css';
import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';

import Gold from './img/Gold.png';
import Ruby from './img/Ruby.png';
import header from './img/header.png'

const members = [
{
  name:"SoYoung Kwon",
  tier:"Gold",
  subtitle:"Miss Washington 1991"
},
{
  name:"Carolyn Ladd",
  tier:"Gold",
  subtitle:"Miss Oregon 1991"
},
{
  name:"Carolyn Graham",
  tier:"Gold",
  subtitle:"Windermere Professional Partners - Meridian"
},
{
  name:"Friends of the MWSO",
  tier:"Gold",
  subtitle:""
},
{
  name:"Rick & Sheri Backous",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Krystle Ramos",
  tier:"Gold",
  subtitle:"MWSO Marketing and Teen Director"
},
{
  name:"Frank & Kay Gallo",
  tier:"Gold",
  subtitle:""
},
{
  name:"Donna Hamilton",
  tier:"Gold",
  subtitle:""
},
{
  name:"Mark & Lea Louder",
  tier:"Gold",
  subtitle:""
},
{
  name:"Patti Belik",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Darrin Emerick",
  tier:"Gold",
  subtitle:""
},
{
  name:"Linda & Dan Pike",
  tier:"Gold",
  subtitle:"Parents of Amber Pike, Miss East Cascades"
},
{
  name:"Payton May and Family",
  tier:"Ruby",
  subtitle:""
},
{
  name:"John & Shirlee Partridge",
  tier:"Gold",
  subtitle:""
},
{
  name:"Jill Smith",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Jim & Diane Dunn",
  tier:"Gold",
  subtitle:""
},
{
  name:"Jay & Jeanie Kincy",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Gary Umemoto & Jay Gleason",
  tier:"Gold",
  subtitle:""
},
{
  name:"Troy & Dee Jackson",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Sarah Aanderud Wahlen",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Steve Phan",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Nicole Renard",
  tier:"Gold",
  subtitle:""
},
{
  name:"Joan Dehn Whidden",
  tier:"Gold",
  subtitle:""
},
{
  name:"Julia Brown",
  tier:"Gold",
  subtitle:""
},
{
  name:"Rachael Livingston",
  tier:"Gold",
  subtitle:""
},
{
  name:"Tanya Aarhus",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Schaefer Consultants",
  tier:"Gold",
  subtitle:""
},
{
  name:"Carol Starcher",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Wendy & Randy Hurst",
  tier:"Gold",
  subtitle:""
},
{
  name:"Kelly Goodman",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Nicholas Latham",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Patricia Spencer",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Lynnae Thurik Benson",
  tier:"Gold",
  subtitle:"Miss Washington 1990"
},
{
  name:"Teresa Lavender",
  tier:"Gold",
  subtitle:"Team Tri-Cities"
},
{
  name:"Lana & Mike Monan",
  tier:"Gold",
  subtitle:"Miss Seattle Scholarship Org."
},
{
  name:"Adam & Richele Strong",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Pamela & Victor Renard",
  tier:"Gold",
  subtitle:""
},
{
  name:"Talia Hastie",
  tier:"Gold",
  subtitle:"Miss Washington Alumnae"
},
{
  name:"Valley Auto Liquidators",
  tier:"Gold",
  subtitle:""
},
{
  name:"Dawn and Tim Kuhn",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Tina Scott",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Amanda Bass & Jessica Hanson",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Ashley Whitley",
  tier:"Gold",
  subtitle:"Washington Center for Business"
},
{
  name:"Jennifer Goble",
  tier:"Ruby",
  subtitle:"Crown and Etch "
},
{
  name:"Brad and Heidi Faulk",
  tier:"Ruby",
  subtitle:""
},
{
  name:"Mike & Peggy Miller",
  tier:"Gold",
  subtitle:""
},
];

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  maxHeight:'80%',
  maxWidth:'80%',
  overflow:'auto'
};

function App() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div className="App">
      <IconButton aria-label="info" size="large">
      <InfoIcon fontSize="large" sx={{ color:"#777263", fontSize:"50px" }} onClick={handleOpen}/>
      </IconButton>
<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={modalStyle}>
    <Typography id="modal-modal-title" variant="h6" component="h2" sx={{textAlign:"center"}}>
      The Apple Crown Club
      <button style={{float:"right"}} onClick={handleClose}>X</button>
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      The Apple Crown Club is an exclusive club for supporters of The Miss Washington Scholarship Organization. Members have contributed a minimum of $100 (larger donatations are gladly accepted) to help with the scholarships awarded during the Miss Washington competition season each year.
      <br/><br/>
      100% of the Apple Crown Club donations go directly to the MWSO Miracle Inc., our 501(c)3 Organization, to help award each year's scholarships. This website features our gracious <span className="ruby" style={{color:"#b31e2d"}}>Ruby</span> and <span className="gold" style={{color:"#dbcf48"}}>Gold</span> crown members. Thank you for your generosity and commitment in supporting the educational empowerment of women across Washington state!
      <br/>To learn more about the club and how you can join, click the button below.
    </Typography>
    <br/>
    <Button variant="contained" sx={{margin: '0 auto', display: "flex",width:"200px",backgroundColor:"#cc4172",':hover': {backgroundColor:"#a8326d"}}} target="_blank" href="https://form.jotform.com/misswa/2024-apple-crown-club-membership" disableElevation >Learn more and join</Button>
    <p id="credits">This site was developed with &hearts; by Miss Puget Sound 2024, <a className='link' href="https://www.jericahuang.com">Jerica Huang</a>.<br/>
    <br/>Logos and trademarks attributed to the Miss Washington Scholarship Organization.<br/>
    <br/>Background image credit from <a className='link' href="https://www.pexels.com/photo/close-up-of-blooming-cherry-tree-8336114/" target="_blank">Pexels</a>.</p>
  </Box>
</Modal>
      <span className="pre">Miss Washington Scholarship Organization</span>
      <h1>Apple Crown Club</h1>
      <img src={header} className="header" width="400" draggable="false"/>
      <h3>2024</h3>
      
      <div className="tree">
      {members.map((member,i)=>{
        return <div key={i} className="apple" style={{backgroundImage:`url(${(member.tier==="Ruby"?Ruby:Gold)})`, paddingTop:`${(member.subtitle===""?"78px":"66px")}`}}>{member.name}<br/><span className="subtitle">{member.subtitle}</span></div>
        })}
    </div>
    </div>
  );
}

export default App;
